import React, { Fragment, useEffect, useState } from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Card,
    Chip,
    Container,
    Divider,
    Grid,
    MenuItem,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { formatPhoneNumber } from "react-phone-number-input";

import { capitalize, getPublicImage, isMFRGroup } from "../../utils/helpers";
import { COACH_MY_ACCOUNT_ROUTE, PROVIDER_CLINIC_PROFILE_ROUTE, PROVIDER_SEARCH_ROUTE } from "../../utils/routes";
import { createPhoneDataPoint, getMfrProvider, updateProviderProfile } from "../../app/actions";

import Button from "../button/Button";
import ButtonLink from "../button/ButtonLink";
import ButtonMain from "../button/ButtonMain";
import EditBioModal from "./EditBioModal";
import EditCredentialsModal from "./EditCredentialsModal";
import EditExpertiseModal from "./EditExpertiseModal";
import EditKeywordsModal from "./EditKeywordsModal";
import EditProviderContactModal from "./EditProviderContactModal";
import EditProviderImagesModal from "./EditProviderProfileImage";
import EditProviderInfoModal from "./EditProviderInfoModal";
import MenuPopover from "../minimal/MenuPopover";
import MFRHeader from "../../layouts/dashboard/header/MFRHeader";
import Modal from "../modal/Modal";
import Page from "../minimal/Page";
import ProviderContactForm from "./ProviderContactForm";
import ShareButton from "../share_button/ShareButton";
import Spinner from "../spinner/Spinner";
import SvgIconStyle from "../minimal/SvgIconStyle";
import { toast } from "../notifications/Toast";
import TSAvatar from "../avatar/TSAvatar";

export default function ProviderProfile() {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const { providerId } = useParams();

    const me = useSelector(({ me }) => me);
    const mfr = useSelector(({ mfr }) => mfr);
    const { provider } = useSelector(({ mfr }) => mfr);
    const [showContactForm, setShowContactForm] = useState(false);
    const [canEditProfile, setCanEditProfile] = useState(false);
    const [showEditProviderImageModal, setShowEditProviderImageModal] = useState(false);
    const [showEditProviderInfoModal, setShowEditProviderInfoModal] = useState(false);
    const [showEditProviderCredentialsModal, setShowEditProviderCredentialsModal] = useState(false);
    const [showEditProviderBioModal, setShowEditProviderBioModal] = useState(false);
    const [showEditProviderContactModal, setShowEditProviderContactModal] = useState(false);
    const [showEditProviderExpertiseModal, setShowEditProviderExpertiseModal] = useState(false);
    const [showEditKeywordsModal, setShowEditKeywordsModal] = useState(false);
    const [showUnpublishedModal, setShowUnpublishedModal] = useState(false);
    const [showUnassignedModal, setShowUnassignedModal] = useState(false);
    const [showPhone, setShowPhone] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    // const [showBlurb, setShowBlurb] = useState(false);
    const [metaKeywords, setMetaKeywords] = useState("");
    const isMFR = isMFRGroup(me.group);
    const mfrDomain =
        window.location.hostname.includes("mfrtherapists.com") || window.location.hostname.includes("mfrhealth.com");

    useEffect(() => {
        async function init() {
            const result = await dispatch(getMfrProvider(providerId, { page_size: 0 }));
            const { error, data } = result;

            if (error) {
                history.push(PROVIDER_SEARCH_ROUTE);
            } else if (
                (data.user && me.id === data.user.id) ||
                (data.user && data.user.group && data.user.group.access_level && data.user.group.access_level === 40)
            ) {
                setCanEditProfile(true);
                setShowPhone(true);
            }

            const windowSlug = window.location.pathname.split("/").pop();
            if (windowSlug !== data.slug) history.push(PROVIDER_SEARCH_ROUTE);

            const defaultKeywords = [];
            defaultKeywords.push(`${data.user.first_name} ${data.user.last_name}`);
            data && data.clinics.length > 0 && data.clinics.map((clinic) => defaultKeywords.push(clinic.title));
            data &&
                data.profile_credentials.length > 0 &&
                data.profile_credentials.map((c) => defaultKeywords.push(c.credential));
            const userKeywords = data.keywords ? data.keywords.split(",") : [];
            const keywords = [...defaultKeywords, ...userKeywords];
            setMetaKeywords(keywords);
        }

        init();
    }, []); // eslint-disable-line

    const toggleShowPhone = () => {
        if (provider && provider.id) dispatch(createPhoneDataPoint(provider.id));

        setShowPhone(true);
    };

    const toggleContactForm = () => {
        setShowContactForm(!showContactForm);
    };

    // const toggleBlurb = () => {
    //     setShowBlurb(true);
    // };

    const showEditProfileModal = (component) => () => {
        switch (component) {
            case "credentials":
                setShowEditProviderCredentialsModal(true);
                break;
            case "bio":
                setShowEditProviderBioModal(true);
                break;
            case "contact":
                setShowEditProviderContactModal(true);
                break;
            case "images":
                setShowEditProviderImageModal(true);
                break;
            case "expertise":
                setShowEditProviderExpertiseModal(true);
                break;
            case "keywords":
                setShowEditKeywordsModal(true);
                break;
            default:
                setShowEditProviderInfoModal(true);
                break;
        }
    };

    const closeEditModal = () => {
        if (showEditProviderImageModal) setShowEditProviderImageModal(false);
        if (showEditProviderInfoModal) setShowEditProviderInfoModal(false);
        if (showEditProviderCredentialsModal) setShowEditProviderCredentialsModal(false);
        if (showEditProviderBioModal) setShowEditProviderBioModal(false);
        if (showEditProviderContactModal) setShowEditProviderContactModal(false);
        if (showEditProviderExpertiseModal) setShowEditProviderExpertiseModal(false);
        if (showEditKeywordsModal) setShowEditKeywordsModal(false);

        if (!provider.active) {
            setShowUnpublishedModal(true);
        }
    };

    const publishProvider = async () => {
        const body = {
            active: true,
        };

        const result = dispatch(updateProviderProfile(provider.id, body));
        const { error } = result;

        setShowUnpublishedModal(false);

        if (error) {
            toast.error("Error publishing profile");
        } else {
            if (provider.clinics && provider.clinics.length === 0) {
                setShowUnassignedModal(true);
            } else {
                toast.success("Profile published");
            }
        }
    };

    const closeUnpublishModal = () => {
        setShowUnpublishedModal(false);
    };

    const closeUnassignModal = () => {
        setShowUnassignedModal(true);
    };

    const redirectToAccount = () => {
        history.push(COACH_MY_ACCOUNT_ROUTE);
    };

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenu(null);
    };

    const toggleStatus = () => {
        dispatch(updateProviderProfile(provider.id, { active: !provider.active }));
    };

    const menuActions = (
        <>
            <MenuItem
                onClick={() => {
                    toggleStatus();
                    handleCloseMenu();
                }}
                className="w-100 p-2"
            >
                <div className="d-flex justify-content-between w-100">
                    {provider && provider.active ? "Unpublish Profile" : "Publish profile"}
                    <SvgIconStyle
                        src={getPublicImage(provider && provider.active ? "ic_eye_off.svg" : "ic_eye.svg")}
                        className="ts-edit-user-icon"
                        alt="Edit User"
                    />
                </div>
            </MenuItem>
        </>
    );

    const EditButton = ({ section }) => {
        return (
            <ButtonLink onClick={showEditProfileModal(section)}>
                <SvgIconStyle
                    src={getPublicImage("ic_edit.svg")}
                    sx={{
                        color: theme.palette.tertiary.main,
                        "&:hover": {
                            color: `${theme.palette.tertiary.darker} !important`,
                        },
                    }}
                    className="hover-pointer"
                />
            </ButtonLink>
        );
    };

    const ContactInfo = () => {
        return (
            <>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" sx={{ fontWeight: 700, color: theme.palette.grey[800] }}>
                        Contact
                    </Typography>
                    {provider && provider.user && !canEditProfile && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            {/* <SvgIconStyle src={getPublicImage("ic_mail.svg")} sx={{ color: theme.palette.grey[600] }} /> */}
                            <Typography
                                variant="body2"
                                sx={{ display: "flex", alignItems: "center", color: theme.palette.grey[600] }}
                            >
                                {/* Email:{" "} */}
                                <ButtonMain
                                    analyticsClass="button__contact-provider-email"
                                    onClick={toggleContactForm}
                                    className="ts-contact-button ml-1"
                                >
                                    Contact now
                                </ButtonMain>
                            </Typography>
                        </Stack>
                    )}
                    {canEditProfile && <EditButton section="contact" />}
                </Stack>
                <Stack sx={{ backgroundColor: theme.palette.grey[100], borderRadius: "20px", p: 2 }}>
                    {provider.phone && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_phone.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <Typography
                                variant="body2"
                                sx={{ display: "flex", alignItems: "center", color: theme.palette.grey[600] }}
                            >
                                Phone:{" "}
                                {showPhone ? (
                                    <a href={`tel:${provider.phone}`} className="ml-1">
                                        {formatPhoneNumber(provider.phone)}
                                    </a>
                                ) : (
                                    <ButtonLink
                                        analyticsClass="button__provider-show-phone"
                                        onClick={toggleShowPhone}
                                        className="ts-contact-button ml-1"
                                        styleOverride={{ fontSize: "14px" }}
                                    >
                                        Show phone
                                    </ButtonLink>
                                )}
                            </Typography>
                        </Stack>
                    )}
                    {showPhone && provider.alt_phone && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_phone.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <Typography
                                variant="body2"
                                sx={{ display: "flex", alignItems: "center", color: theme.palette.grey[600] }}
                            >
                                Alt. Phone:{" "}
                                <a href={`tel:${provider.alt_phone}`}>{formatPhoneNumber(provider.alt_phone)}</a>
                                {provider.vanity_phone && (
                                    <Typography sx={{ ml: 0.5 }}>{provider.vanity_phone}</Typography>
                                )}
                            </Typography>
                        </Stack>
                    )}
                    {provider.alt_website && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle src={getPublicImage("ic_web.svg")} sx={{ color: theme.palette.grey[600] }} />
                            <a
                                href={provider.alt_website}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ts-contact-button ts-external-link text-main-alt ml-2"
                            >
                                <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                                    {provider.user ? `${provider.user.first_name}'s` : ""} Website
                                </Typography>
                            </a>
                        </Stack>
                    )}
                    {provider.facebook && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_facebook_logo.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <a
                                href={provider.facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ts-contact-button text-main-alt ml-2"
                            >
                                <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                                    Facebook
                                </Typography>
                            </a>
                        </Stack>
                    )}
                    {provider.instagram && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_instagram_logo.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <a
                                href={provider.instagram}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ts-contact-button text-main-alt ml-2"
                            >
                                <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                                    Instagram
                                </Typography>
                            </a>
                        </Stack>
                    )}
                    {provider.twitter && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_twitter_logo.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <a
                                href={provider.twitter}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ts-contact-button text-main-alt ml-2"
                            >
                                <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                                    Twitter
                                </Typography>
                            </a>
                        </Stack>
                    )}
                    {provider.linkedin && (
                        <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 0.5 }}>
                            <SvgIconStyle
                                src={getPublicImage("ic_linkedin_logo.svg")}
                                sx={{ color: theme.palette.grey[600] }}
                            />
                            <a
                                href={provider.linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ts-contact-button text-main-alt ml-2"
                            >
                                <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                                    LinkedIn
                                </Typography>
                            </a>
                        </Stack>
                    )}
                </Stack>
            </>
        );
    };

    const LocationInfo = () => {
        return (
            <>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" sx={{ fontWeight: 700, color: theme.palette.grey[800], mb: 1 }}>
                        Locations
                    </Typography>
                </Stack>
                <Stack sx={{ backgroundColor: theme.palette.grey[100], borderRadius: "20px", p: 2 }}>
                    {provider &&
                        provider.clinics &&
                        provider.clinics.length > 0 &&
                        provider.clinics.map((clinic, idx) => (
                            <Fragment key={idx}>
                                <Stack
                                    sx={{
                                        ml: 2,
                                        my: 2,
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    <Stack>
                                        <Typography
                                            variant="body1"
                                            sx={{ color: theme.palette.grey[600], fontWeight: 700 }}
                                        >
                                            {clinic.title}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
                                            {clinic.address1} {clinic.address2} {clinic.city}, {clinic.state}{" "}
                                            {clinic.zip}
                                        </Typography>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", mt: 2, mr: 4 }}>
                                        <ButtonLink
                                            analyticsClass="button__view-provider-info"
                                            href={`${generatePath(PROVIDER_CLINIC_PROFILE_ROUTE, {
                                                clinicId: clinic.id,
                                            })}/${clinic.slug}`}
                                            rel="canonical"
                                            title={`${clinic.title}'s profile`}
                                            className="ts-contact-button ml-1"
                                            underline
                                        >
                                            View {clinic.title}'s profile
                                        </ButtonLink>
                                        {!canEditProfile && (
                                            <ButtonLink
                                                analyticsClass="button__contact-provider"
                                                onClick={toggleContactForm}
                                                className="ts-contact-button ml-3"
                                                underline
                                                styleOverride={{ ml: 2 }}
                                            >
                                                Contact {provider && provider.user && provider.user.first_name}
                                            </ButtonLink>
                                        )}
                                    </Stack>
                                </Stack>
                                {provider &&
                                    provider.clinics &&
                                    provider.clinics.length > 1 &&
                                    idx < provider.clinics.length - 1 && <Divider />}
                            </Fragment>
                        ))}
                </Stack>
            </>
        );
    };

    return (
        <Page
            title={`Myofascial Release Therapy ${
                provider &&
                provider.clinics &&
                provider.clinics.length &&
                provider.clinics.length > 0 &&
                provider.clinics[0] &&
                provider.clinics[0].city &&
                provider.clinics[0].state
                    ? `in ${provider.clinics[0].city}, ${provider.clinics[0].state}`
                    : ""
            } by ${provider && provider.user && `${provider.user.first_name} ${provider.user.last_name}`}`}
            mfr={mfrDomain}
            mfrProvider={isMFR && me.is_coach}
            meta={[{ property: "keywords", content: metaKeywords }]}
        >
            {mfr.mfrProviderIsFetching ? (
                <Stack sx={{ minHeight: "400px", alignItems: "center", justifyContent: "center" }}>
                    <Spinner />
                </Stack>
            ) : (
                <Container maxWidth="xl">
                    {me && !me.id && <MFRHeader />}
                    {provider && !provider.active && canEditProfile && (
                        <Alert severity="warning">
                            <AlertTitle>This profile is hidden</AlertTitle>This profile is currently hidden from public
                            view. Click the status button below when you are ready to publish.
                        </Alert>
                    )}
                    {provider ? (
                        <Card sx={{ p: { xs: 3, lg: "75px" }, mt: !me.id ? "100px" : 2, mb: 2 }}>
                            {canEditProfile && (
                                <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
                                    <ButtonMain
                                        onClick={handleOpenMenu}
                                        sx={{
                                            backgroundColor: !provider.active
                                                ? theme.palette.warning.main
                                                : theme.palette.primary.main,
                                            color: !provider.active
                                                ? theme.palette.warning.contrastText
                                                : theme.palette.text.white,
                                            "&:hover": {
                                                backgroundColor: !provider.active
                                                    ? theme.palette.warning.main
                                                    : theme.palette.primary.main,
                                                color: !provider.active
                                                    ? theme.palette.warning.contrastText
                                                    : theme.palette.text.white,
                                            },
                                            padding: "8px 16px !important",
                                            mb: 1,
                                            fontWeight: 500,
                                        }}
                                    >
                                        <SvgIconStyle
                                            src={getPublicImage(provider.active ? "ic_eye.svg" : "ic_eye_off.svg")}
                                            className="ts-edit-user-icon mr-2"
                                            alt="Toggle status"
                                        />
                                        {provider.active ? "Public" : "Hidden"}
                                    </ButtonMain>
                                    <MenuPopover
                                        open={Boolean(openMenu)}
                                        anchorEl={openMenu}
                                        onClose={handleCloseMenu}
                                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                        disabledArrow
                                        sx={{
                                            mt: -1,
                                            width: 200,
                                            "& .MuiMenuItem-root": {
                                                px: 1,
                                                typography: "body2",
                                                borderRadius: 0.75,
                                                "& svg": { mr: 2, width: 20, height: 20 },
                                            },
                                        }}
                                    >
                                        {menuActions}
                                    </MenuPopover>
                                </Stack>
                            )}
                            <Stack
                                sx={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    mb: 4,
                                }}
                            >
                                {provider &&
                                provider.user &&
                                provider.user.group &&
                                provider.user.group.clinics.length > 0 ? (
                                    <Stack flexDirection="column">
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: theme.palette.grey[800],
                                                fontSize: "1rem !important",
                                            }}
                                        >
                                            {`Myofascial Release Therapy in ${provider.user.group.clinics[0].city}, ${provider.user.group.clinics[0].state}`}
                                        </Typography>
                                        <Typography
                                            variant="h2"
                                            sx={{
                                                color: theme.palette.grey[800],
                                                fontSize: "2rem !important",
                                                fontWeight: "700 !important",
                                                lineHeight: "1.33 !important",
                                            }}
                                        >
                                            {provider.user.group.clinics[0].title}
                                        </Typography>
                                    </Stack>
                                ) : (
                                    <Box></Box>
                                )}
                                <Box>
                                    <ShareButton profileId={provider.id} />
                                </Box>
                            </Stack>
                            {canEditProfile && (
                                <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
                                    <ButtonLink onClick={showEditProfileModal("keywords")}>Manage keywords</ButtonLink>
                                </Stack>
                            )}
                            <Stack
                                sx={{
                                    flexDirection: "row",
                                    alignItems: "start",
                                    display: { xs: "none", lg: "flex" },
                                    mb: "85px",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "500px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {canEditProfile && (
                                        <Stack
                                            className="ts-edit-profile-images-overlay hover-pointer"
                                            onClick={showEditProfileModal("images")}
                                        >
                                            <SvgIconStyle src={getPublicImage("ic_edit.svg")} />
                                            <Typography variant="caption">Edit Image</Typography>
                                        </Stack>
                                    )}
                                    <TSAvatar
                                        image={provider && provider.photo}
                                        name={provider && provider.user && provider.user.first_name}
                                        size="lg"
                                    />
                                </Box>
                                <Stack
                                    sx={{
                                        width: "100%",
                                        ml: provider && provider.user && provider.photo ? 4 : 2,
                                    }}
                                >
                                    <Stack flexDirection="row" alignItems="start">
                                        <Typography
                                            variant="h1"
                                            sx={{ color: theme.palette.grey[800], fontSize: "3rem !important", mr: 1 }}
                                        >
                                            {provider && provider.user && provider.user.first_name}{" "}
                                            {provider && provider.user && provider.user.last_name}
                                        </Typography>
                                        {canEditProfile && <EditButton section="info" />}
                                    </Stack>

                                    <Grid container>
                                        {provider.title && (
                                            <Grid
                                                item
                                                xs={7}
                                                sx={{ display: "flex", alignItems: "center", height: "32px" }}
                                            >
                                                <Typography variant="headline" sx={{ color: theme.palette.grey[600] }}>
                                                    {provider.title}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item xs={provider.title ? 5 : 12}>
                                            <Grid
                                                container
                                                spacing={1}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    mt: "0px !important",
                                                    ml: provider.title ? 0.5 : 0,
                                                }}
                                            >
                                                {provider &&
                                                provider.profile_credentials &&
                                                provider.profile_credentials.length > 0 ? (
                                                    provider.profile_credentials.map((cred, idx) => (
                                                        <Fragment key={idx}>
                                                            {cred && cred.credential && (
                                                                <Grid item sx={{ pt: "0px !important" }}>
                                                                    <Chip
                                                                        label={cred.credential}
                                                                        sx={{
                                                                            fontWeight: 600,
                                                                            mt: "0px !important",
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Fragment>
                                                    ))
                                                ) : (
                                                    <>
                                                        {canEditProfile && (
                                                            <Typography variant="headline" sx={{ fontStyle: "italic" }}>
                                                                Tell us what you specialize in
                                                            </Typography>
                                                        )}
                                                    </>
                                                )}
                                                <Grid
                                                    item
                                                    sx={{
                                                        pt: "0px !important",
                                                        pr: provider.title ? 0 : 1,
                                                        // mr: "12px",
                                                    }}
                                                >
                                                    {canEditProfile && <EditButton section="credentials" />}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {provider.expertise_level ? (
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    height: "32px",
                                                }}
                                            >
                                                <Chip
                                                    label={capitalize(provider.expertise_level)}
                                                    sx={{
                                                        fontWeight: 600,
                                                        mt: "0px !important",
                                                        backgroundColor: theme.palette.slate.main,
                                                        color: theme.palette.common.white,
                                                    }}
                                                />
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ display: "flex", justifyContent: "end", height: "32px" }}
                                                >
                                                    {canEditProfile && <EditButton section="expertise" />}
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <>
                                                {canEditProfile ? (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            height: "32px",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="headline"
                                                            sx={{ fontStyle: "italic", mr: "12px" }}
                                                        >
                                                            Your Expertise level
                                                        </Typography>
                                                        <EditButton section="expertise" />
                                                    </Grid>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </Grid>

                                    <Divider sx={{ mt: 3, mb: 1 }} />
                                    <Stack
                                        sx={{
                                            flexDirection: "row",
                                            alignItems: "start",
                                            mt: 2,
                                            justifyContent: !provider.bio && "space-between",
                                        }}
                                    >
                                        {provider.bio && provider.bio.length > 0 ? (
                                            <Stack
                                                sx={{
                                                    flexDirection: canEditProfile ? "row" : "column",
                                                    color: theme.palette.grey[800],
                                                    alignItems: "start",
                                                }}
                                            >
                                                <Typography
                                                    variant="headline"
                                                    sx={{ color: theme.palette.grey[800], whiteSpace: "pre-wrap" }}
                                                >
                                                    {provider.bio}
                                                </Typography>
                                            </Stack>
                                        ) : (
                                            <>
                                                {canEditProfile && (
                                                    <Typography variant="headline" sx={{ fontStyle: "italic" }}>
                                                        Tell us a little bit about yourself
                                                    </Typography>
                                                )}
                                            </>
                                        )}
                                        {canEditProfile && <EditButton section="bio" />}
                                    </Stack>
                                    <Box sx={{ my: 2, display: { xs: "none", lg: "block" } }}>
                                        <ContactInfo />
                                    </Box>
                                    {provider && provider.clinics && provider.clinics.length > 0 && (
                                        <Box sx={{ my: 2, display: { xs: "none", lg: "block" } }}>
                                            <LocationInfo />
                                        </Box>
                                    )}
                                    {/* {provider &&
                                        provider.clarifying_content_blurb &&
                                        provider.clarifying_content_blurb.length > 0 && (
                                            <Box>
                                                <Typography
                                                    className={cx(
                                                        showBlurb ? "ts-clarifying-blurb" : "ts-clarifying-blurb-hidden"
                                                    )}
                                                >
                                                    {provider.clarifying_content_blurb}
                                                </Typography>
                                                {!showBlurb && (
                                                    <Button
                                                        link
                                                        className="ts-clarifying-blurb-read-more"
                                                        onClick={toggleBlurb}
                                                    >
                                                        <span className="px-2 py-1">Read more</span>
                                                    </Button>
                                                )}
                                            </Box>
                                        )} */}
                                </Stack>
                            </Stack>
                            {/* Mobile layout */}
                            <Box sx={{ display: { xs: "block", lg: "none" } }}>
                                <div>
                                    <Stack flexDirection="row" justifyContent="space-between" alignItems="end">
                                        <Typography variant="h3" sx={{ mt: 2 }}>
                                            {provider && provider.user && provider.user.first_name}{" "}
                                            {provider && provider.user && provider.user.last_name}
                                        </Typography>
                                        {canEditProfile && <EditButton section="info" />}
                                    </Stack>
                                    {provider.title && (
                                        <Typography variant="headline" sx={{ color: theme.palette.grey[600] }}>
                                            {provider.title}
                                        </Typography>
                                    )}
                                    {provider.expertise_level ? (
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                height: "32px",
                                            }}
                                        >
                                            <Chip
                                                label={capitalize(provider.expertise_level)}
                                                sx={{
                                                    fontWeight: 600,
                                                    mt: "0px !important",
                                                    backgroundColor: theme.palette.slate.main,
                                                    color: theme.palette.common.white,
                                                }}
                                            />
                                            <Grid
                                                item
                                                xs={6}
                                                sx={{ display: "flex", justifyContent: "end", height: "32px" }}
                                            >
                                                {canEditProfile && <EditButton section="expertise" />}
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                height: "32px",
                                            }}
                                        >
                                            {canEditProfile && (
                                                <Typography variant="headline" sx={{ fontStyle: "italic" }}>
                                                    Your Expertise level
                                                </Typography>
                                            )}
                                            <Grid
                                                item
                                                xs={6}
                                                sx={{ display: "flex", justifyContent: "end", height: "32px" }}
                                            >
                                                {canEditProfile && <EditButton section="expertise" />}
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item xs={5}>
                                        <Grid
                                            container
                                            spacing={1}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                mt: "0px !important",
                                            }}
                                        >
                                            <Grid item xs={11} sx={{ display: "flex" }}>
                                                {provider &&
                                                provider.profile_credentials &&
                                                provider.profile_credentials.length > 0 ? (
                                                    provider.profile_credentials.map((cred, idx) => (
                                                        <Fragment key={idx}>
                                                            {cred && cred.credential && (
                                                                <Grid item sx={{ px: 0.5, pt: "0px !important" }}>
                                                                    <Chip
                                                                        label={cred.credential}
                                                                        sx={{
                                                                            fontWeight: 600,
                                                                            mt: "0px !important",
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Fragment>
                                                    ))
                                                ) : (
                                                    <>
                                                        {canEditProfile && (
                                                            <Typography variant="headline" sx={{ fontStyle: "italic" }}>
                                                                Tell us what you specialize in
                                                            </Typography>
                                                        )}
                                                    </>
                                                )}
                                            </Grid>
                                            <Grid item sx={{ pt: "0px !important" }}>
                                                {canEditProfile && <EditButton section="credentials" />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Divider sx={{ mt: 3, mb: 1 }} />
                                <Stack
                                    sx={{
                                        mb: "85px",
                                        mt: 2,
                                        justifyContent: "center",
                                        alignItems: "start",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Stack>
                                        <TSAvatar
                                            image={provider.photo}
                                            name={provider && provider.user && provider.user.first_name}
                                            size="lg"
                                        />
                                    </Stack>
                                    {canEditProfile && (
                                        <Stack
                                            className="ts-edit-profile-images-overlay hover-pointer"
                                            onClick={showEditProfileModal("images")}
                                        >
                                            <SvgIconStyle src={getPublicImage("ic_edit.svg")} />
                                            <Typography variant="caption">Edit Image</Typography>
                                        </Stack>
                                    )}
                                </Stack>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: "flex", lg: "none" },
                                    alignItems: "start",
                                    justifyContent: !provider.bio && "space-between",
                                }}
                            >
                                {provider.bio && provider.bio.length > 0 ? (
                                    <Typography
                                        variant="headline"
                                        sx={{ color: theme.palette.grey[800], whiteSpace: "pre-wrap" }}
                                    >
                                        {provider.bio}
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant="headline"
                                        sx={{ fontStyle: "italic", color: theme.palette.grey[800] }}
                                    >
                                        Tell us a little bit about yourself
                                    </Typography>
                                )}
                                {canEditProfile && <EditButton section="bio" />}
                            </Box>
                            <Stack sx={{ my: 2, display: { xs: "block", lg: "none" } }}>
                                <ContactInfo />
                            </Stack>
                            {provider && provider.clinics && provider.clinics.length > 0 && (
                                <Stack sx={{ my: 2, display: { xs: "block", lg: "none" } }}>
                                    <LocationInfo />
                                </Stack>
                            )}
                        </Card>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minHeight: "400px",
                                width: "100%",
                            }}
                        >
                            <Spinner />
                        </Box>
                    )}
                    {/* Wait for provider to exist */}
                    {provider && provider.id && (
                        <ProviderContactForm
                            show={showContactForm}
                            provider={provider}
                            toggleShow={toggleContactForm}
                        />
                    )}

                    <EditProviderImagesModal
                        show={showEditProviderImageModal}
                        provider={provider}
                        handleClose={closeEditModal}
                    />
                    <EditProviderInfoModal
                        show={showEditProviderInfoModal}
                        provider={provider}
                        handleClose={closeEditModal}
                    />
                    <EditCredentialsModal
                        show={showEditProviderCredentialsModal}
                        credentials={provider && provider.profile_credentials}
                        providerId={provider && provider.id}
                        handleClose={closeEditModal}
                    />
                    <EditKeywordsModal show={showEditKeywordsModal} provider={provider} handleClose={closeEditModal} />
                    <EditBioModal show={showEditProviderBioModal} provider={provider} handleClose={closeEditModal} />
                    <EditProviderContactModal
                        show={showEditProviderContactModal}
                        provider={provider}
                        handleClose={closeEditModal}
                    />
                    <EditExpertiseModal
                        show={showEditProviderExpertiseModal}
                        provider={provider}
                        handleClose={closeEditModal}
                    />
                    <Modal
                        show={showUnpublishedModal}
                        content="To be searchable on the MFR directory, you need to publish your profile. Would you like to publish your profile?"
                        toggleShow={closeUnpublishModal}
                        actions={[
                            <Button
                                key="no-button"
                                linkDefault
                                square
                                className="p-3 mr-2"
                                onClick={closeUnpublishModal}
                            >
                                Leave unpublished
                            </Button>,
                            <Button key="yes-button" compact secondary square onClick={publishProvider}>
                                Publish
                            </Button>,
                        ]}
                    />
                    <Modal
                        show={showUnassignedModal}
                        content="Your profile is now published but you still need to create a clinic. Go to the Clinic tab on the Account page."
                        toggleShow={closeUnassignModal}
                        actions={[
                            <Button key="no-button" linkDefault square className="p-3 mr-2" onClick={redirectToAccount}>
                                Take me there
                            </Button>,
                        ]}
                    />
                </Container>
            )}
        </Page>
    );
}
